@import "../../../styles/helpers";

.wrapper {
  margin-top: 128px;
  padding: 0 50px;
  text-align: center;
  background-size: 100%;
  background: url('../../../../public/media/frame-providers.webp') right top no-repeat, url('../../../../public/media/rectangle-green.webp') right top no-repeat;
  background-position: top 220px left 0, bottom 265px right -65px;

  @include media(desktop-small) {
    margin-top: 150px;
    padding-bottom: 110px;
    background-position: top 60px left 0, bottom 0 right 0;
  }

  @include media(desktop) {
    margin-top: 150px;
    padding-bottom: 110px;
    background-position: top 60px left 0, bottom 0 right 0;
  }
}

