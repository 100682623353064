@import "../../../styles/helpers";

$nav-margin: 12px;

.wrapper {
  margin-top: 30px;

  @include media(landscape) {
    width: 708px;
  }

  @include media(desktop-small) {
    width: 708px;
  }

  @include media(desktop) {
    width: 708px;
  }
}

.navButtons {
  color: var(--primary-color);
  font-size: 30px;
  border: unset;

  &:hover {
    background-color: unset;
  }
}

.navButtonLeft {
  @extend .navButtons;
  margin-right: $nav-margin;
}

.navButtonRight {
  @extend .navButtons;
  margin-left: $nav-margin;
}

.tabItem {
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  color: var(--primary-color);
  background-color: transparent;
  border: 1px solid var(--primary-color);
  box-shadow: none;
    
  a {
    padding: 10px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%; 
  }
}