@import "../../../styles/helpers";

.wrapper {
  padding: 64px 26px 0 24px;
  background-size: 100%;
  background: url('../../../../public/media/search-frame.webp') right top no-repeat, url('../../../../public/media/search-rectangle.webp') right top no-repeat;
  background-position: top 20px right 0, top -66px right 0;

  @include media(desktop-small) {
    padding: 140px 26px 34px 142px;
    background: url('../../../../public/media/search-frame-desktop.webp') right top no-repeat, url('../../../../public/media/search-rectangle-desctop.webp') right top no-repeat;
    background-position: top 65px right 0, top -40px right 0;
  }

  @include media(desktop) {
    padding: 140px 26px 34px 142px;
    background: url('../../../../public/media/search-frame-desktop.webp') right top no-repeat, url('../../../../public/media/search-rectangle-desctop.webp') right top no-repeat;
    background-position: top 65px right 0, top -40px right 0;
  }
}

.text {
  font-size: 60px;
  font-weight: 600;

  @include media(desktop-small) {
    font-size: 96px;
  }

  @include media(desktop) {
    font-size: 96px;
  }

  span {
    color: var(--primary-color);
  }
}
