@import "src/styles/helpers";

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: var(--margin-sm);
  background-color: var(--footer-color);

  .logoWrapper {
    align-self: flex-start;
    margin-bottom: 24px;
  }

  @include media(desktop-small) {
    height: 80px;
    flex-direction: row;
    margin-top: 0;

    .logoWrapper {
      align-self: center;
      margin-bottom: 0;
    }
  }

  @include media(desktop) {
    height: 80px;
    flex-direction: row;
    margin-top: 0;

    .logoWrapper {
      align-self: center;
      margin-bottom: 0;
    }
  }
}

.rights {
  padding: var(--margin-sm);
  background-color: var(--footer-rights-color);
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  @include media(desktop-small) {
    flex-direction: row;
    align-items: center;
  }

  @include media(desktop) {
    flex-direction: row;
    align-items: center;
  }
}

.designLink {
  margin-left: 4px;
  text-decoration: underline;
}
