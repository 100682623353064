@import "src/styles/helpers";

.wrapper {
  margin-top: var(--margin-xxl);
  flex-direction: column;

  @include media(desktop) {
    display: block;
    margin-top: var(--margin-xl);
  }

  .add-listing-link {
    width: fit-content;
    display: inline-block;
  }
}