.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
  }
}

.link {
  margin-right: var(--margin-sm);
  display: flex;
  align-items: center;
}