@mixin font-face($font-family, $file-path, $weight: normal, $style: normal) {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
    src: url('#{$file-path}.ttf') format('ttf'), url('#{$file-path}.ttf') format('truetype');
  }
}

@mixin multi-line-ellipsis($lines-to-show) {
  display: block; // Fallback for non-webkit
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
}
